import styled from 'styled-components'

const Styling = styled.div`

    .main_title{
    width: 100%;
    font-size:clamp(4rem, 4vw, 8rem);
    text-transform:uppercase;
    ${({theme}) => theme.tomato('regular')}  
    letter-spacing: -0.09em;
    display: flex;
    align-items:center;
    margin-bottom:4rem;
    
        svg{
        margin-right: 1rem;
        width: clamp(3rem, 2.5vw, 6rem);
            path{
                fill:var(--black);
            }
        }
    }
    
    .text{
    max-width:80%;
    /* font-size:clamp(1.6rem, 1vw, 3rem);
    line-height:clamp(1.6rem*1.25, 1vw*1.25, 3rem*1.25); */
    font-size:1.6rem;
    line-height:2.5rem;
    
        p{
        margin-bottom:2rem;
        
            &::last-child{
            margin-bottom:0;
            }
        }
    
    }

.alumni{
width: 100%;
background:var(--white);
display: flex;
min-height:calc(100vh - var(--footerHeight));
flex-wrap: wrap;
padding:10rem 0 0rem 0;

    @media only screen and (min-width: 1024px) {
    flex-wrap: nowrap;
    padding:10vw 0;
    }

    .left{
    width:100%;
    padding-left: 2rem;
        
        @media only screen and (min-width: 350px) {
        padding-left: 4rem;
        }
    
        @media only screen and (min-width: 1024px) {
        padding-left: 8rem;
        width: 50%;
        }
    }

    .right{
    width:100%;
    padding:2rem 2rem 0 2rem;
    
        @media only screen and (min-width: 350px) {
        padding:2rem 4rem 0 4rem;
        }

        @media only screen and (min-width: 1024px) {
        width:50%;
        padding:0 8rem 0 0;
        }

       

        .form{
        width: 100%;

            .capsule_button{
            cursor: pointer;
            }

            p{
            margin-bottom: 1rem;
            }

        }

    }
}
`

export { Styling }