import styled from 'styled-components'

const SplitStyling = styled.div`
width: 100%;
background:var(--white);
display:flex;
align-items:flex-start;

    .main_title{
    width: 100%;
    font-size:clamp(4rem, 4vw, 8rem);
    text-transform:uppercase;
    ${({theme}) => theme.tomato('regular')}  
    letter-spacing: -0.09em;
    display: flex;
    align-items:center;
    margin-bottom:4rem;
    
        svg{
        margin-right: 1rem;
        width: clamp(3rem, 2.5vw, 6rem);
            path{
                fill:var(--black);
            }
        }
    }

    .form-text{
    font-size:1.6rem;
    line-height:2.5rem;
    margin-bottom: 4rem;
    
        ul{
        display: flex;
        flex-wrap: wrap;

            li{
            width: 100%;
            position: relative;
            padding-left:1rem;

                @media only screen and (min-width: 600px) {
                    width: 50%;
                }

                &:before{
                content:"";
                width:0.5rem;
                height:0.5rem;
                border-radius: 100%;
                background: var(--black);
                position: absolute;
                left:0;
                top:50%;
                transform: translateY(-50%);
                }

            }
        }
    
        p{
        margin-bottom:2rem;
        
            &:last-child,
            &:last-of-type{
            margin-bottom:0;
            }
        }
    
    }
    
    .text{
    max-width:80%;
    /* font-size:clamp(1.6rem, 1vw, 3rem);
    line-height: 1.5em; */
    font-size:1.6rem;
    line-height:2.5rem;
    
        p{
        margin-bottom:2rem;
        
            &::last-child{
            margin-bottom:0;
            }
        }
    
    }

    .right,
    .left{
    height:100%;
    width: 100%;
    min-height:73rem;
    height:100vh;
    /* height:calc(100vh - var(--footerHeight)); */
    display: flex;
    align-items:center;
    flex-wrap: wrap;
    align-content: center;

        @media only screen and (min-width: 1024px) {
        width: 50vw;
        }

    }

    .right{
    padding:4rem;
    position: relative;
    overflow: hidden;
    padding:10rem 2rem 4rem 2rem;

        @media only screen and (min-width: 350px) {
        padding:4rem;
        }

        @media only screen and (min-width: 1024px) {
        padding:10rem 8rem 8rem 8rem;
        }

        .right-inner{
            max-width:60rem;
            margin:0 auto;
            overflow: hidden;

                .text{
                max-width: 100%;
                }

        }

        &.alumni-right{
            
            @media only screen and (min-width: 350px) {
                padding-top:4rem;
            }
            @media only screen and (min-width: 1024px) {
                padding-top:16rem;
            }
            
        }

        .main_title{
        max-width: 440px;
        /* max-width: 350px; */
        /* margin: 0 auto 4rem auto; */
        }

        .form{
        width: 100%;
        max-width: 350px;
        /* margin: 0 auto; */

            .capsule_button{
            cursor: pointer;
            }

        }

        .translator{
        width: 100%;
        height:100%;
        transform:translateX(100%);
        position: absolute;
        top:0;
        left:0;
        display: flex;
        align-items:center;
        flex-wrap: wrap;
        align-content: center;
        opacity: 0;
        padding:0 2rem;
        line-height: 1.5em;

            @media only screen and (min-width: 350px) {
            padding:0 4rem;
            }

            @media only screen and (min-width: 1024px) {
            padding:0 8rem;
            }
        
            &.active{
            transform:translateX(0%);
            opacity: 1;
            }

        }


    }

    .left{
    display: none;
    position: relative;

        @media only screen and (min-width: 1024px) {
        display: flex;
        }

        .gatsby-image-wrapper{
        position: absolute !important;
        height:100%;
        width: 100%;
        }
    }

`

export { SplitStyling }