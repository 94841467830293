import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { Styling } from "../styles/pages/alumni.styled";
import { SplitStyling } from "../styles/pages/default-split.styled";
import Arrow from "../images/arrow_down_right.inline.svg";
import { handleLogin, isLoggedIn, isAlumni } from "../utils/auth";
import { navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import gsap from "gsap";
import axios from "axios";
import Seo from "../components/global/seo";

export default function Alumni({ data }) {
  let rememberedUser;
  if (typeof window !== "undefined") {
    rememberedUser = window?.localStorage?.rememberUser;
  } else {
    rememberedUser = undefined;
  }

  const [username, setUsername] = useState(rememberedUser);
  const [password, setPassword] = useState();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [resetEmail, setResetEmail] = useState();
  // const [isAlumni, setIsAlumni] = useState(false)
  const [validationCode, setValidationCode] = useState();
  const [resetPassword, setResetPassword] = useState();
  const [remember, setRemember] = useState(false);
  const baseUrl = "https://admin.toolroomacademy.com/wp-json/bdpwr/v1/";
  const resetPasswordUrl = baseUrl + "reset-password";
  const validateCodeUrl = baseUrl + "validate-code";
  const setNewPasswordUrl = baseUrl + "set-password";
  const [isLoggedInAlumni, setIsLoggedInAlumni] = useState(false);

  function reset(resetEmail) {
    if (typeof window !== "undefined") {
      axios
        .post(resetPasswordUrl, {
          email: resetEmail,
        })
        .then((r) => {
          //console.log(r.data);
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  }

  function validate(validationCode) {
    if (typeof window !== "undefined") {
      axios
        .post(validateCodeUrl, {
          email: resetEmail,
          code: String(validationCode),
        })
        .then((r) => {
          //console.log(r.data);
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  }

  function setNewPassword(validationCode, resetPassword) {
    if (typeof window !== "undefined") {
      axios
        .post(setNewPasswordUrl, {
          email: resetEmail,
          code: String(validationCode),
          password: resetPassword,
        })
        .then((r) => {
          //console.log(r.data);
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  }

  const handleUpdateResetEmail = (event) => {
    setResetEmail(event.target.value);
  };
  const handleUpdateValidationCode = (event) => {
    setValidationCode(event.target.value);
  };
  const handleUpdateUsername = (event) => {
    setUsername(event.target.value);
  };
  const handleUpdatePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleUpdateResetPassword = (event) => {
    setResetPassword(event.target.value);
  };

  const handleResetAndTransition = () => {
    reset(resetEmail);
    setCurrentSlideIndex(1);
  };
  const handleValidateAndTransition = () => {
    validate(validationCode);
    setCurrentSlideIndex(2);
  };
  const handlePasswordAndTransition = () => {
    setNewPassword(validationCode, resetPassword);
    resetFormSlidesToInitialState();
  };

  useEffect(() => {
    gsap.to(".alumni-right  .reset-form .form-styles", {
      x: `-${150 * currentSlideIndex}%`,
    });
  }, [currentSlideIndex]);

  const handleInitialFormTranslate = () => {
    if (typeof window !== "undefined") {
      const loginForm = document.querySelector(".alumni-right .login-form");
      const resetForm = document.querySelector(".alumni-right .reset-form");

      gsap.to(loginForm, { x: "-150%" });
      gsap.to(resetForm, { x: "-0%", delay: 0.3 });
    }
  };
  const resetFormSlidesToInitialState = () => {
    if (typeof window !== "undefined") {
      const loginForm = document.querySelector(".alumni-right .login-form");
      const resetForm = document.querySelector(".alumni-right .reset-form");
      setCurrentSlideIndex(0);
      gsap.to(resetForm, { x: "150%", delay: 0.3 });
      gsap.to(loginForm, { x: "0%", delay: 0.6 });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const redirectUrl = `/alumni/`;
    const checkAlumni = true;
    handleLogin({ username, password, remember, redirectUrl, checkAlumni });
  };

  useEffect(() => {
    if (window?.localStorage?.gatsbyUser) {
      let checkAlumniTest = JSON.parse(window?.localStorage?.gatsbyUser);
      setIsLoggedInAlumni(checkAlumniTest.alumni);
    }
  });

  return (
    <Styling>
      <Seo
        title={data.wpPage.seo.title}
        description={data.wpPage.seo.metaDesc}
      />
      {isLoggedInAlumni ? (
        <div className="alumni">
          <div className="left">
            <h2 className="main_title">
              <Arrow /> Alumni
            </h2>
            <div className="text">
              {parse(data.wpPage.alumniFields.alumniText)}
            </div>
          </div>
          <div className="right">
            <div className="right-inner">
              <div className="form">
                <form
                  action="https://getform.io/f/606ad0a4-4cbb-405f-b212-f7e864c1e2d4"
                  method="POST"
                >
                  <div className="form-styles">
                    <input
                      type="text"
                      className="col-2"
                      placeholder="Artist Name*"
                      name="artist-name"
                      required
                    />
                    <input
                      type="text"
                      className="col-2"
                      placeholder="Track Title*"
                      name="track-title"
                      required
                    />
                    <input
                      type="text"
                      className="col-2"
                      placeholder="First Name*"
                      name="first-name"
                      required
                    />
                    <input
                      type="text"
                      className="col-2"
                      placeholder="Last Name*"
                      name="last-name"
                      required
                    />
                    <input
                      type="email"
                      className="col-2"
                      placeholder="Email address*"
                      name="email"
                      required
                    />
                    <select className="col-2" required>
                      <option selected disabled>
                        Genre *
                      </option>
                      <option className="option" value="Deep House">
                        Deep House
                      </option>
                      <option
                        className="option"
                        value="Funky / Groove / Jackin' House"
                      >
                        Funky / Groove / Jackin' House
                      </option>
                      <option className="option" value="House">
                        House
                      </option>
                      <option className="option" value="Nu-Disco">
                        Nu-Disco
                      </option>
                      <option
                        className="option"
                        value="Melodic House &amp; Techno"
                      >
                        Melodic House &amp; Techno
                      </option>
                      <option className="option" value="Minimal / Deep Tech">
                        Minimal / Deep Tech
                      </option>
                      <option className="option" value="Progressive House">
                        Progressive House
                      </option>
                      <option className="option" value="Tech House">
                        Tech House
                      </option>
                      <option className="option" value="Techno">
                        Techno
                      </option>
                      <option className="option" value="Other">
                        Other
                      </option>
                    </select>
                    <input
                      type="text"
                      className="col-2"
                      placeholder="Phone Number*"
                      name="phone"
                      required
                    />
                    <input
                      type="text"
                      className="col-2"
                      placeholder="Country"
                      name="country"
                    />
                    <input
                      type="text"
                      className="col-1"
                      placeholder="Soundcloud Link*"
                      name="soundcloud"
                      required
                    />
                    <textarea className="col-1" placeholder="Notes"></textarea>
                    <div className="checkbox-wrapper col-1">
                      <span>
                        <input type="checkbox" name="permit" id="permit" />
                        <span></span>
                      </span>
                      <label htmlFor="permit">
                        I confirm that I am permitted to submit this track for
                        review to Toolroom Productions Ltd and that I am not
                        infringing anybody else’s rights by doing so. I agree
                        for my information to be stored for the purpose of this
                        review and to be contacted by Toolroom Productions Ltd
                        regarding this music submission.
                      </label>
                    </div>
                  </div>
                  <input
                    type="submit"
                    className="capsule_button black"
                    value="Submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SplitStyling>
          <div className="left image-wrapper">
            <GatsbyImage
              image={getImage(
                data?.wpPage?.alumniFields?.alumniFormImage?.localFile
              )}
              className="image"
              alt={"test"}
            />
          </div>
          <div className="right alumni-right">
            <div className="right-inner">
              <h2 className="main_title">
                <Arrow /> Alumni
              </h2>
              <div className="form-text">
                {parse(data?.wpPage?.alumniFields?.alumniTextBeforeLoginForm)}
              </div>
              <div className="forms-wrapper">
                <form
                  method="post"
                  className="login-form"
                  onSubmit={(event) => {
                    handleSubmit(event);
                  }}
                >
                  <div className="form-styles">
                    <div className="validation-message">
                      Incorrect username and/or password
                    </div>
                    <input
                      type="text"
                      placeholder="Username/Email"
                      className="col-1"
                      name="username"
                      onChange={(e) => handleUpdateUsername(e)}
                      value={username}
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      className="col-1"
                      name="password"
                      onChange={(e) => handleUpdatePassword(e)}
                    />
                    <div className="checkbox-wrapper">
                      <span>
                        <input
                          type="checkbox"
                          name="remember-me"
                          id="remember-me"
                          onClick={() => setRemember(true)}
                        />
                        <span></span>
                      </span>
                      <label htmlFor="remember-me">Remember Me</label>
                    </div>
                    <div className="buttons">
                      <span
                        className="fakeButton"
                        onClick={() => handleInitialFormTranslate()}
                      >
                        Forgot your password
                      </span>
                    </div>

                    <input
                      type="submit"
                      className={`submit_button realCapsuleButton capsule_button black ${
                        username === undefined ||
                        password === undefined ||
                        username === "" ||
                        password === ""
                          ? "disabled"
                          : ""
                      }`}
                      value={"Login"}
                    />
                    <div className="submit_button capsule_button black fakeCapsuleButton">
                      Logging in{" "}
                      <AiOutlineLoading3Quarters className="svgWrap" />
                    </div>
                  </div>
                </form>
                <div className="reset-form fakeForm">
                  <div className="form-styles">
                    <div className="form-step-1 form-step">
                      <input
                        type="text"
                        placeholder="Email address"
                        onChange={(e) => handleUpdateResetEmail(e)}
                        className="reset-email col-1"
                      />
                      <button
                        onClick={() => handleResetAndTransition()}
                        className="send-reset-form submit_button capsule_button black col-1"
                      >
                        Send reset code
                      </button>
                    </div>
                    <div className="form-step-2 form-step">
                      <input
                        type="text"
                        placeholder="Validation code"
                        onChange={(e) => handleUpdateValidationCode(e)}
                        className="reset-validation col-1"
                      />
                      <button
                        onClick={() => handleValidateAndTransition()}
                        className="send-reset-form submit_button capsule_button black col-1"
                      >
                        Validate code
                      </button>
                    </div>
                    <div className="form-step-3 form-step">
                      <input
                        type="password"
                        placeholder="Enter new password"
                        onChange={(e) => handleUpdateResetPassword(e)}
                        className="reset-password col-1"
                      />
                      <button
                        onClick={() => handlePasswordAndTransition()}
                        className="send-reset-form submit_button capsule_button black col-1"
                      >
                        Reset password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SplitStyling>
      )}
    </Styling>
  );
}

export const query = graphql`
  {
    wpPage(title: { eq: "Alumni" }) {
      id
      seo {
        metaDesc
        title
      }
      alumniFields {
        alumniText
        alumniTextBeforeLoginForm
        alumniFormImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2000
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;
